@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-black dark:text-white;
  }

  code {
    @apply text-accentContrast dark:text-accent;
  }

  code::before {
    content: "`";
  }

  code.undecorated::before {
    content: "";
  }

  code::after {
    content: "`";
  }

  code.undecorated::after {
    content: "";
  }
}

@layer components {
  .btn-primary {
    @apply bg-black dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-800 hover:shadow-md transition-all text-base text-white rounded inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-white {
    @apply bg-white hover:bg-gray-200 hover:shadow-md transition-all text-base text-black rounded inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-outlined {
    @apply border border-gray-400 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 hover:shadow-md transition-all text-base text-black dark:text-gray-200 rounded inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-xl {
    @apply px-12 h-16;
  }

  .btn-lg {
    @apply px-8 h-12;
  }

  .btn-md {
    @apply px-6 h-10;
  }

  .btn-sm {
    @apply px-4 h-8 text-xs;
  }

  .btn-xs {
    @apply px-2 h-6 text-xxs font-bold;
  }

  .textbox-outlined {
    @apply focus:outline-none rounded border border-gray-200 focus:ring-2 focus-within:ring-2  ring-gray-400 focus:border-black focus-within:border-black;
  }

  .textbox-md {
    @apply px-4 h-10;
  }

  .textbox-lg {
    @apply px-4 h-12;
  }

  .custom-container {
    @apply container mx-auto px-4 xl:max-w-6xl 2xl:max-w-6xl;
  }

  .gradient-text {
    @apply bg-gradient-to-tr from-red-500 to-accent bg-clip-text text-transparent;
  }

  .dropdown-items {
    @apply absolute right-0 w-32 mt-2 origin-top-right bg-white dark:bg-gray-800 text-white dark:text-black divide-y divide-gray-100 rounded shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
  }

  .link {
    @apply border-b border-dotted border-black text-black dark:border-white dark:text-white hover:text-gray-400;
  }

  .link[target="_blank"]::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
  }

  .link-black {
    @apply border-b border-dotted border-black text-black hover:text-gray-400;
  }

  .link-white {
    @apply border-b border-dotted border-white text-white hover:text-gray-400;
  }

  .auth-input {
    @apply transition py-5 px-6 bg-white w-full border border-gray-400 rounded-md focus:outline-none focus:ring ring-white;
  }

  .code-block::before {
    content: "";
  }

  .code-block::after {
    content: "";
  }

  .disabled-input {
    @apply disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200;
  }

  .information-box {
    @apply text-xxs border border-gray-200 flex gap-2 text-gray-500 items-center rounded-md max-w-max h-8;
    padding: 5px 10px;
  }
  .information-box > svg {
    @apply h-3;
  }
  .information-box > span {
    @apply font-semibold text-gray-500 text-xxs;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.animated-border {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-width: 0px !important;
}
.animated-border:before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  width: 200%;
  background-color: rgba(115, 209, 60, 1);
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 49%,
    rgba(230, 230, 230, 1) 51%,
    rgba(230, 230, 230, 1) 100%
  );
  animation: rotate 2s linear infinite;
  padding-top: 200%;
}

.animated-border:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: white;
  border-radius: 6px;
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}

.Toastify__toast {
  @apply relative py-2 px-4 mb-1 rounded-md flex justify-between shadow-lg overflow-hidden cursor-pointer border border-gray-200 dark:border-gray-900;
}

.Toastify__toast--default {
  @apply bg-white dark:bg-gray-800 text-black dark:text-white;
}

.Toastify__toast--info {
  @apply bg-blue-500;
}

.Toastify__toast--success {
  @apply bg-green-500;
}

.Toastify__toast--warning {
  @apply bg-yellow-500;
}

.Toastify__toast--error {
  @apply bg-red-500;
}

.Toastify__toast-body {
  @apply flex-1 text-xs;
}

.db_setup_flow_container > div > div {
  height: 100%;
}
