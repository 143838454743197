@font-face {
  font-family: "Dr";
  src: url("/static/fonts/dr/drweb-regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Dr";
  src: url("/static/fonts/dr/drweb-medium.woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Dr";
  src: url("/static/fonts/dr/drweb-bold.woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Dr";
  src: url("/static/fonts/dr/drweb-extrabold.woff");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Dr";
  src: url("/static/fonts/dr/drweb-extrabold.woff");
  font-weight: 900;
  font-display: swap;
}
