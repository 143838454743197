@font-face {
  font-family: "PlusJakarta";
  src: url("/static/fonts/plusjakarta/PlusJakartaSans-Regular.woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakarta";
  src: url("/static/fonts/plusjakarta/PlusJakartaSans-Medium.woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakarta";
  src: url("/static/fonts/plusjakarta/PlusJakartaSans-Bold.woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakarta";
  src: url("/static/fonts/plusjakarta/PlusJakartaSans-ExtraBold.woff2");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakarta";
  src: url("/static/fonts/plusjakarta/PlusJakartaSans-Black.woff2");
  font-weight: 900;
  font-display: swap;
}
